import getTermsAndConditionsTemplateService
  from '~/src/pages/legal/terms-and-conditions/styles/GetTermsAndConditionsTemplateService'
import StylesTermsAndConditionsServiceInterface
  from '~/src/pages/legal/terms-and-conditions/styles/StylesTermsAndConditionsServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetTermsAndConditionsTemplate = (): StylesTermsAndConditionsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('termsAndConditions')

  useLayout('termsAndConditions')

  return getTermsAndConditionsTemplateService(pageStyle, muiTheme)
}

export default useGetTermsAndConditionsTemplate
