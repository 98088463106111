import { Box, Typography } from '@mui/material'
import Card from '~/src/components/mui-wrappers/Card'
import Title from '~/src/components/mui-wrappers/Title'
import GoBackButton from '~/src/components/navigation/GoBackButton'
import useGetTermsAndConditionsTemplate
  from '~/src/pages/legal/terms-and-conditions/styles/useGetTermsAndConditionsTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const TermsAndConditions = () => {
  const pageName: keyof typeof RoutesEnum = 'TERMS_AND_CONDITIONS'
  const styles = useGetTermsAndConditionsTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>

      <Box
        sx={styles?.getPageSx()}
      >
        <Card sx={styles?.getCardSx()}>
          <GoBackButton />
          <Title
            variant='h4'
            sx={{ my: 2 }}
          >TERMS OF USE for Customer</Title>
          <Typography variant='body2'>The Terms of Use for Customers are applicable to the relations between EASYORDER
            BVBA and the Customers. For the Terms of Use for Online Shop we refer to the General term and
            conditions.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Definitions</Title>
          <Typography variant='body2'>Customer: a natural person or legal entity placing an Order through the
            Platform</Typography>
          <Typography variant='body2'>Offer: the selection of Products/Services offered by the Online Shop that can be
            ordered by the Customer through the Platform.</Typography>
          <Typography variant='body2'>Order: an order placed by the Customer with the Online Shop through the
            Platform.</Typography>
          <Typography variant='body2'>Agreement: an agreement between the Customer and the Online Shop regarding an Order
            and the delivery or collection of the Order.</Typography>
          <Typography variant='body2'>Platform: the website, apps and tools and other equipment of EASYORDER BVBA and its
            affiliated companies and business partners on which the Online Shop is made available on.</Typography>
          <Typography variant='body2'>Platform Service: the commercial services and/or activities that are offered to the
            Customer by Platform, including showing the Offer, facilitation of the conclusion of Agreements and
            transmission of Orders to the Online shop.</Typography>
          <Typography variant='body2'>Online Shop: a company that offering products and/or services through the Platform
            with the purpose to sell these products/services to a customer.</Typography>
          <Typography variant='body2'>Online Shop information: the information about the Online shop like, company and
            contact information, general information, product range, prices for each individual product/service (including
            VAT), company logo, graphics, delivery area, delivery costs and minimum order amounts, general sales
            conditions.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >General</Title>
          <Typography variant='body2'>This Platform is operated by EASYORDER BVBA, BTW BE 0655.723.562, Ikaroslaan 24,
            1930 Zaventem, Belgium. EASYORDER BVBA, also refers by EASYORDER, offers this Platform including all
            information, tools and services conditioned upon your acceptance of all the following Terms of Use. EASYORDER
            BVBA is a member of the KEYWARE Group all companies affiliated with KEYWARE TECHNOLOGIES NV, being the
            companies in which KEYWARE TECHNOLOGIES NV exercised control in accordance with Article 5 of the Companies
            Code</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Applicability</Title>
          <Typography variant='body2'>The present Terms of Use for Customers are only applicable to the Platform Service.
            EASYORDER BVBA is not responsible for the Offer/Order/Agreement. Towards the execution of an agreement only
            the general terms and conditions of the Online Shop shall be applicable.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Execution of Agreement</Title>
          <Typography variant='body2'>By placing an Order, the Customer directly concludes an Agreement with the Online
            Shop for delivery and/or collection of the by the Customer selected products/services. The Agreement is
            effective as from the moment the Customer finalizes the Order by clicking the 'Confirm Order' button during
            the process of placing an Order through the Platform. After receipt of the Order, the Platform will
            electronically confirm the Order to the Customer and make available for the Online Shop. The Agreement can
            only be executed by the Online Shop if the Customer provides correct and complete contact and address
            information when placing the Order. In general, the Customer shall not be entitled to dissolve the Agreement.
            Cancellation of the Order with the Online Shop is only possible by the Customer if the Online shop explicitly
            indicates that Cancellation of the Order by the Customer is possible. The Online Shop is entitled to cancel
            the Order, the Agreement e.g., if the Offer is no longer available, if the Customer has provided an incorrect
            or inoperative contact information, or in case of force majeure. Complaints from the Customer about the Offer,
            the Order or the performance of the Agreement, need to be addressed towards Online Shop. The sole
            responsibility for to execution of the Agreement lies with the Online Shop.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Accuracy, Completeness and Timeliness of Information on this Platform</Title>
          <Typography variant='body2'>EASYORDER is not responsible if information made available on this Platform is not
            accurate, complete or current. The material on this Platform is provided by the Online Shop.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Intellectual Property</Title>
          <Typography variant='body2'>EASYORDER retains all rights (including copyrights, trademarks as well as any other
            intellectual property right) in relation to all information provided on or via this Platform (including all
            texts, graphics and logos), excluding the Online Shop Information. You may not copy, download, publish,
            distribute or reproduce any of the information contained on this Platform in any form without the prior
            written consent of EASYORDER or the appropriate consent of the owner. However, you may print out and/or
            download information contained on this web site for your own personal, non-commercial use.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Conduct on the Site</Title>
          <Typography variant='body2'>For the execution of an agreement on this platform personal data need to be
            provided. By registering at and/or when providing personal data, in consideration of your use of the platform,
            you agree to provide true, accurate, current and complete information about yourself. More information about
            how EASYORDER handles personal data is detailed in the Privacy statement</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Linked Sites</Title>
          <Typography variant='body2'>This Platform may provide links or references from or to other external web sites
            (inclusive the Online shop web site when available). EASYORDER have not necessarily reviewed all the
            information on those other sites and are not responsible for the content of those or any other sites or any
            products or services that may be offered through those or any other sites. Different terms and conditions may
            apply to your use of any linked sites. EASYORDER disclaims any responsibility for the content, use or
            availability of such other sites and is not responsible for any losses, damages or other liabilities incurred
            as a result of your use of any linked sites.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Disclaimer</Title>
          <Typography variant='body2'>Your use of this Platform is at your sole risk. EASYORDER and the Online Shop will
            use reasonable efforts to provide accurate information on this Platform. Given the changing nature of laws,
            rules and regulations, and the inherent hazards of electronic communication, there may be delays, omissions or
            inaccuracies in information contained in this Platform. EASYORDER and Online Shop expressly disclaims all
            warranties of any kind, whether express or implied, including but not limited to the implied warranties of
            merchantability and fitness for a particular purpose of (including but not limited to) any information
            contained on/or provided via this Platform and/or any service described or promoted on this Platform,
            including warranties with respect to infringement of any patent, copyright, or other rights of third
            parties.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Limitation of Liability</Title>
          <Typography variant='body2'>EASYORDER and Online Shop shall not be liable for any injury, loss, damage or
            expense arising out of any access to or use of this Platform or any site linked to it, including, without
            limitation, any loss of profit, indirect, incidental or consequential loss.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Underage</Title>
          <Typography variant='body2'>The sale of alcoholic drinks is prohibited for minors.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Changes to These Terms of Use</Title>
          <Typography variant='body2'>EASYORDER may at any time make changes to these terms of use by posting revised
            terms on the site. It is your responsibility to check periodically for any changes we make to the Terms of
            Use. Your continued use of the site after any changes to the Terms of Use or other policies means you accept
            the changes.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Jurisdiction and Applicable Law</Title>
          <Typography variant='body2'>By accessing this Platform, you agree that the laws of Belgium shall apply to all
            matters arising from or relating to the use and contents of this web site or information provided to you via
            this Platform. You also agree to submit to the exclusive jurisdiction in first instance of the District Court
            of Brussels, Belgium with respect to such matters. We recognize that it is possible for you to obtain access
            to this Platform from any jurisdiction in the world, but we have no practical ability to prevent such access.
            This Platform has been designed to comply with the laws of Belgium. If any material on this Platform, or your
            use of the Platform, is contrary to the laws of the place where you are when you access it, the Platform is
            not intended for you, and we ask you not to use the Platform. You are responsible for informing yourself of
            the laws of your jurisdiction and complying with them.</Typography>
        </Card>
      </Box>
    </SlideFromRight>
  )
}

export default TermsAndConditions
