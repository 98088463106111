import StylesTermsAndConditionsServiceInterface
  from '~/src/pages/legal/terms-and-conditions/styles/StylesTermsAndConditionsServiceInterface'
import StylesTermsAndConditionsServiceStyleOne
  from '~/src/pages/legal/terms-and-conditions/styles/StylesTermsAndConditionsServiceStyleOne'
import StylesTermsAndConditionsServiceStyleThree
  from '~/src/pages/legal/terms-and-conditions/styles/StylesTermsAndConditionsServiceStyleThree'
import StylesTermsAndConditionsServiceStyleTwo
  from '~/src/pages/legal/terms-and-conditions/styles/StylesTermsAndConditionsServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getTermsAndConditionsTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesTermsAndConditionsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesTermsAndConditionsServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesTermsAndConditionsServiceStyleThree(muiTheme)
    default:
      return new StylesTermsAndConditionsServiceStyleOne(muiTheme)
  }
}

export default getTermsAndConditionsTemplateService
