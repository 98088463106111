import { SxProps } from '@mui/material'
import StylesTermsAndConditionsServiceInterface
  from '~/src/pages/legal/terms-and-conditions/styles/StylesTermsAndConditionsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesTermsAndConditionsServiceStyleTwo extends TemplateServiceStyleBase implements StylesTermsAndConditionsServiceInterface {
  public getPageSx(): SxProps {
    return {
      display: 'grid',
      py: 3,
      gridTemplateColumns: 'auto 66% auto',
      gridTemplateRows: 'auto',
      gridTemplateAreas: '". center ."',
      [this.muiTheme.breakpoints.down('md')]: {
        py: 0,
        gridTemplateAreas: '"center center center"'
      }
    }
  }

  public getCardSx(): SxProps {
    return {
      gridArea: 'center',
      p: 3
    }
  }
}
